<template>
  <HeaderPricing />
  <Pricing />
  <Footer />
</template>

<script>
import HeaderPricing from "@/components/services/pricing/HeaderPricing.vue";
import Footer from "@/components/Footer.vue";
import Pricing from "@/components/services/pricing/Pricing.vue";

export default {
  components: {
    HeaderPricing,
    Footer,
    Pricing,
  },
};
</script>
