<template>
  <HeaderNotFound />
  <Socialpage />
  <Footer />
</template>

<script>
// @ is an alias to /src
import HeaderNotFound from "@/components/notfound/HeaderNotFound.vue";
import Footer from "@/components/Footer.vue";
import Socialpage from "@/components/notfound/NotFound.vue";

export default {
  components: {
    HeaderNotFound,
    Footer,
    Socialpage,
  },
};
</script>
