<template>
  <svg
    width="654"
    height="267"
    viewBox="0 0 654 267"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_748_3271)">
      <path
        d="M189.276 172.107H165.397V101.898C165.397 98.1685 163.913 94.5921 161.273 91.9552C158.632 89.3183 155.05 87.8369 151.315 87.8369H145.719C143.87 87.8369 142.039 88.2006 140.33 88.9073C138.622 89.6139 137.069 90.6496 135.761 91.9552C134.454 93.2609 133.416 94.8109 132.709 96.5168C132.001 98.2228 131.637 100.051 131.637 101.898V172.107H64.2813C62.8957 172.107 61.5336 171.75 60.3271 171.069C59.1207 170.389 58.1108 169.408 57.3956 168.223C56.6804 167.039 56.2841 165.689 56.2451 164.306C56.2061 162.923 56.5258 161.554 57.1731 160.33L128.461 25.6134C129.35 23.9338 129.889 22.0918 130.045 20.1986C130.201 18.3054 129.971 16.4003 129.37 14.5982C128.768 12.7961 127.807 11.1344 126.544 9.71343C125.282 8.29245 123.744 7.14166 122.023 6.33052L117.815 4.34634C114.547 2.80565 110.811 2.57816 107.38 3.71092C103.949 4.84369 101.086 7.25 99.3822 10.432L12.4368 172.828C10.9562 175.593 10.1816 178.681 10.1816 181.816C10.1816 186.873 12.1935 191.723 15.7746 195.298C19.3558 198.874 24.2128 200.882 29.2773 200.882H131.637V245.279C131.637 249.748 133.415 254.035 136.581 257.196C139.746 260.357 144.04 262.133 148.517 262.133V262.133C152.994 262.133 157.287 260.357 160.453 257.196C163.619 254.035 165.397 249.748 165.397 245.279V200.882H189.276C191.169 200.882 193.043 200.51 194.791 199.787C196.539 199.064 198.128 198.004 199.466 196.668C200.804 195.332 201.865 193.746 202.589 192.001C203.314 190.255 203.686 188.384 203.686 186.495V186.495C203.686 182.679 202.168 179.02 199.466 176.321C196.763 173.623 193.098 172.107 189.276 172.107V172.107Z"
        stroke="url(#paint0_linear_748_3271)"
        stroke-miterlimit="10"
      />
      <path
        d="M179.276 176.107H155.397V105.898C155.397 102.169 153.913 98.5921 151.272 95.9552C148.631 93.3183 145.05 91.837 141.315 91.837H135.719C133.87 91.837 132.039 92.2006 130.33 92.9073C128.622 93.6139 127.069 94.6496 125.761 95.9552C124.454 97.2609 123.416 98.8109 122.709 100.517C122.001 102.223 121.637 104.051 121.637 105.898V176.107H54.2813C52.8957 176.107 51.5335 175.75 50.3271 175.069C49.1206 174.389 48.1108 173.408 47.3956 172.223C46.6804 171.039 46.2841 169.689 46.2451 168.306C46.2061 166.923 46.5257 165.554 47.173 164.33L118.461 29.6134C119.35 27.9338 119.889 26.0918 120.045 24.1986C120.201 22.3055 119.971 20.4003 119.37 18.5982C118.768 16.7961 117.807 15.1344 116.544 13.7134C115.281 12.2925 113.744 11.1417 112.023 10.3305L107.815 8.34635C104.547 6.80566 100.811 6.57815 97.3803 7.71092C93.9493 8.8437 91.0858 11.25 89.3821 14.432L2.43679 176.828C0.956228 179.593 0.18164 182.681 0.181641 185.816V185.816C0.181641 190.873 2.19351 195.723 5.77464 199.298C9.35577 202.874 14.2128 204.882 19.2773 204.882H121.637V249.278C121.637 253.748 123.415 258.035 126.581 261.196C129.746 264.357 134.04 266.133 138.517 266.133V266.133C142.994 266.133 147.287 264.357 150.453 261.196C153.619 258.035 155.397 253.748 155.397 249.278V204.882H179.276C183.098 204.882 186.763 203.367 189.466 200.668C192.168 197.97 193.686 194.311 193.686 190.495V190.495C193.686 186.679 192.168 183.019 189.466 180.321C186.763 177.623 183.098 176.107 179.276 176.107Z"
        fill="url(#paint1_linear_748_3271)"
      />
      <path
        d="M331.398 6.27283C273.643 6.27283 232.175 43.2696 232.175 129.595C232.175 227.431 273.643 253.329 331.398 253.329C389.153 253.329 433.091 224.965 433.091 129.595C433.091 27.6487 389.153 6.27283 331.398 6.27283ZM331.778 220.854C291.775 220.854 270.876 197.422 270.876 129.658C270.876 69.8673 292.631 38.3367 332.633 38.3367C372.636 38.3367 394.39 59.0479 394.39 129.658C394.39 195.713 371.78 220.854 331.778 220.854V220.854Z"
        stroke="url(#paint2_linear_748_3271)"
        stroke-miterlimit="10"
      />
      <path
        d="M319.223 13.6713C261.468 13.6713 220 50.668 220 136.994C220 234.829 261.468 260.727 319.223 260.727C376.978 260.727 420.916 232.363 420.916 136.994C420.916 35.0472 376.978 13.6713 319.223 13.6713ZM319.602 228.252C279.6 228.252 258.701 204.82 258.701 137.057C258.701 77.2657 280.455 45.7351 320.458 45.7351C360.46 45.7351 382.215 66.4464 382.215 137.057C382.215 203.112 359.605 228.252 319.602 228.252Z"
        fill="url(#paint3_linear_748_3271)"
      />
      <path
        d="M637.367 172.107H613.488V101.898C613.488 98.1685 612.004 94.5921 609.363 91.9552C606.722 89.3183 603.14 87.8369 599.405 87.8369H593.81C591.961 87.8369 590.13 88.2006 588.421 88.9073C586.712 89.6139 585.16 90.6496 583.852 91.9552C582.545 93.2609 581.507 94.8109 580.8 96.5168C580.092 98.2228 579.728 100.051 579.728 101.898V172.107H512.372C510.987 172.107 509.624 171.75 508.418 171.069C507.211 170.389 506.202 169.408 505.486 168.223C504.771 167.039 504.375 165.689 504.336 164.306C504.297 162.923 504.617 161.554 505.264 160.33L576.552 25.6134C577.441 23.9338 577.98 22.0918 578.136 20.1986C578.292 18.3054 578.062 16.4003 577.46 14.5982C576.859 12.7961 575.898 11.1344 574.635 9.71343C573.372 8.29245 571.834 7.14166 570.114 6.33052L565.906 4.34634C562.638 2.80565 558.902 2.57816 555.471 3.71092C552.04 4.84369 549.177 7.25 547.473 10.432L460.528 172.828C459.047 175.593 458.272 178.681 458.272 181.816C458.272 186.873 460.284 191.723 463.865 195.298C467.447 198.874 472.304 200.882 477.368 200.882H579.728V245.279C579.728 249.748 581.506 254.035 584.672 257.196C587.837 260.357 592.131 262.133 596.608 262.133V262.133C601.085 262.133 605.378 260.357 608.544 257.196C611.709 254.035 613.488 249.748 613.488 245.279V200.882H637.367C639.26 200.882 641.133 200.51 642.882 199.787C644.63 199.064 646.219 198.004 647.557 196.668C648.895 195.332 649.956 193.746 650.68 192.001C651.404 190.255 651.777 188.384 651.777 186.495V186.495C651.777 182.679 650.259 179.02 647.557 176.321C644.854 173.623 641.189 172.107 637.367 172.107V172.107Z"
        stroke="url(#paint4_linear_748_3271)"
        stroke-miterlimit="10"
      />
      <path
        d="M627.367 176.107H603.488V105.898C603.488 102.169 602.004 98.5921 599.363 95.9552C596.722 93.3183 593.14 91.837 589.405 91.837H583.81C581.961 91.837 580.129 92.2006 578.421 92.9073C576.712 93.6139 575.16 94.6496 573.852 95.9552C572.545 97.2609 571.507 98.8109 570.799 100.517C570.092 102.223 569.728 104.051 569.728 105.898V176.107H502.372C500.986 176.107 499.624 175.75 498.418 175.069C497.211 174.389 496.202 173.408 495.486 172.223C494.771 171.039 494.375 169.689 494.336 168.306C494.297 166.923 494.617 165.554 495.264 164.33L566.552 29.6134C567.441 27.9338 567.98 26.0918 568.136 24.1986C568.292 22.3055 568.062 20.4003 567.46 18.5982C566.859 16.7961 565.898 15.1344 564.635 13.7134C563.372 12.2925 561.834 11.1417 560.114 10.3305L555.906 8.34635C552.638 6.80566 548.902 6.57815 545.471 7.71092C542.04 8.8437 539.177 11.25 537.473 14.432L450.528 176.828C449.047 179.593 448.272 182.681 448.272 185.816V185.816C448.272 190.873 450.284 195.723 453.865 199.298C457.447 202.874 462.304 204.882 467.368 204.882H569.728V249.278C569.728 253.748 571.506 258.035 574.672 261.196C577.837 264.357 582.131 266.133 586.608 266.133V266.133C591.085 266.133 595.378 264.357 598.544 261.196C601.709 258.035 603.488 253.748 603.488 249.278V204.882H627.367C631.189 204.882 634.854 203.367 637.557 200.668C640.259 197.97 641.777 194.311 641.777 190.495V190.495C641.777 186.679 640.259 183.019 637.557 180.321C634.854 177.623 631.189 176.107 627.367 176.107Z"
        fill="url(#paint5_linear_748_3271)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_748_3271"
        x1="128.643"
        y1="11.6419"
        x2="-84.9188"
        y2="148.039"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#D360E5" />
        <stop offset="1" stop-color="#3849FB" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_748_3271"
        x1="96.9339"
        y1="7"
        x2="-63.1546"
        y2="152.228"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#BDBDC1" />
        <stop offset="1" stop-color="#AEAEB5" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_748_3271"
        x1="355.173"
        y1="14.512"
        x2="144.773"
        y2="160.856"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#D360E5" />
        <stop offset="1" stop-color="#3849FB" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_748_3271"
        x1="320.458"
        y1="13.6713"
        x2="320.517"
        y2="260.727"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#565A5D" />
        <stop offset="1" stop-color="#2B2B2B" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_748_3271"
        x1="576.733"
        y1="11.6419"
        x2="363.172"
        y2="148.039"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#D360E5" />
        <stop offset="1" stop-color="#3849FB" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_748_3271"
        x1="545.025"
        y1="7"
        x2="384.936"
        y2="152.228"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#BDBDC1" />
        <stop offset="1" stop-color="#AEAEB5" />
      </linearGradient>
      <clipPath id="clip0_748_3271">
        <rect width="653.091" height="267" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
